import React, { useEffect, useState, useRef } from "react";
import { useScriptConverter } from "../utils/languageHelpers";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import qs from "qs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

// temp
import { useSelector } from "react-redux";

import { MEDIA_STORAGE } from "../config/environment";

// components
import RatioBox from "./elements/RatioBox";
import Copyright from "../components/Copyright";
import ButtonLink from "./elements/ButtonLink";
import T from "./elements/TranslateScript";

//imgs
import children from "./img/HomePage/Children.svg";
import myBestTa from "./img/HomePage/MyBestTA.svg";
import download from "./img/HomePage/Download.svg";
import video from "./img/HomePage/Video.svg";
import downloadIcon from "./img/HomePage/DownloadIcon.png";
import videoIcon from "./img/HomePage/VideoIcon.png";
import systemIcon from "./img/HomePage/SystemIcon.png";

import schools from "./img/HomePage/Schools.svg";
import homepage from "./img/HomePage/home.png";
import logo from "./img/HomePage/LOGO.png";

// utils
import { useSignout, useMyBestUserSignin } from "../utils/authHelpers";
import { MYBESTUSER, CLIENTID } from "../config/environment";

// config
import { default as ACCESS_LEVELS, ALL, ADMINS } from "../config/accessLevels";

// test
import { useLanguage } from "../utils/languageHelpers";

const MainBGContent = styled.div`
  position: relative;
  background: url(${homepage}) no-repeat center center fixed;
  height: 100%;
  background-size: cover;

  display: grid;

  grid-template-columns: 100%;
  grid-template-rows: 10% 90%;
  grid-template-areas:
    "topNav"
    "content";
  /* "footer"; */
`;
const Content = styled.div`
  grid-area: content;
  overflow-y: auto;

  ::-webkit-scrollbar {
    // hide scroll
    width: 0;
  }

  .example::-webkit-scrollbar {
    display: none;
  }
`;

const TopNavBar = styled.div`
  grid-area: topNav;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopNavGrid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 15% 50% 25% 10%;
  grid-template-rows: 100%;

  grid-template-areas: "logo . login lang";
`;

const Logo = styled.div`
  width: 8em;
  height: 4em;

  background-image: url(${logo});
  background-size: 100% 100%;
  grid-area: logo;
`;
const LogIn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  grid-area: login;
`;

const LangSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  grid-area: lang;

  position: relative;

  font-family: "Quicksand", sans-serif;
  color: #9d22cd;
`;

const DefaultLang = styled.div`
  width: 5em;
  height: 2em;
  text-align: center;
  background-color: #fff;
  border-radius: 0.3em;
  border: 0.1em solid #d27890;
  cursor: pointer;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const DropDown = styled.div`
  width: 5em;
  height: 6em;
  border-radius: 0.5em;
  position: absolute;
  top: 75%;

  right: 15%;

  border: 0.1em solid #d27890;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);

  background-color: #fff;
  z-index: 1;
`;

const LangUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  position: relative;
`;

const LangLi = styled.option`
  font-size: 0.8em;
  text-align: center;
  border-radius: 0.5em;
  padding: 0.5em;

  :hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
`;

const SVGArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 5%;
  width: 90%;
  height: 90%;
`;

const Section1DescriptionArea = styled.div`
  width: 50%;
  padding: 1em;

  font-family: "Quicksand", sans-serif;

  font-weight: 700;
`;

const DescriptionTitle = styled.div`
  width: 100%;

  font-size: 1.2em;

  :after {
    content: "";
    display: block;
    height: 4px;
    width: 100%;
    background-color: black;
  }
`;

const Section = styled.section`
  height: 100%;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
`;

const Regular = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChildrenSVG = styled.img`
  vertical-align: middle;
  border-style: none;
  position: absolute;
  margin: auto;
  bottom: 15%;
`;

const MyBestTASVG = styled.img`
  position: absolute;
  vertical-align: middle;
  border-style: none;
  margin: auto;
  top: 0%;
`;

const Part1 = styled.div`
  width: 35%;
  height: 100%;

  display: flex;
  justify-content: center;

  flex-direction: column;
`;

const Part2 = styled.div`
  width: 40%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
`;

const SchoolContainer = styled.div`
  font-weight: bolder;
  font-family: "Quicksand", sans-serif;
  display: flex;
`;

const Footer = styled.footer`
  background-color: rgba(190, 60, 150, 0.7);

  width: 100%;
  height: 50%;

  display: flex;
  align-items: center;

  flex-direction: column;
`;

const CompanyInfoArea = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 100%;

  grid-template-areas: ". . download . .";

  width: 100%;

  height: 65%;

  font-family: "Quicksand", sans-serif;
`;

const DownloadArea = styled.div`
  padding: 1em;
  grid-area: download;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
`;

const InfoTitle = styled.div`
  font-weight: bolder;
  font-size: 1.1em;
`;

const ChromeLink = styled(ButtonLink)`
  width: 8.5em;
  height: 2.5em;
`;
const TeamviewerLink = styled(ButtonLink)`
  width: 11em;
  height: 2.5em;
`;

const AnyDeskLink = styled(ButtonLink)`
  width: 8.5em;
  height: 2.5em;
`;

const CustomizationDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  font-weight: bolder;

  font-family: "Quicksand", sans-serif;
`;

const Description = styled.div`
  font-size: 0.9em;
  font-weight: bolder;
  font-family: "Quicksand", sans-serif;
`;

const ScrollToAnchorButton = styled.div`
  position: absolute;
  bottom: 2%;
  background-color: #a02ccf;
  width: 2.5em;
  height: 2.5em;
  border-radius: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  cursor: pointer;
`;

const LandingPage = () => {
  const signout = useSignout();
  const user = useSelector((state) => state.auth.user);

  const signin = useMyBestUserSignin();

  const os = window.navigator.userAgent;

  const { language, changeLanguage } = useLanguage();

  const converter = useScriptConverter();

  const [isShowLangList, setIsShowLangList] = useState(false);

  const [SectionHeight, setSectionHeight] = useState(0);

  const SectionRef = useRef(null);

  const reportWindowSize = () => {
    setSectionHeight(SectionRef.current?.clientHeight);
  };

  window.addEventListener("resize", reportWindowSize);

  const scrollToAnchor = (position) => {
    document
      .getElementById("scroller")
      .scroll({ top: position, behavior: "smooth" });
  };

  const ToggleLangList = () => {
    setIsShowLangList((preState) => !preState);
  };

  const handleChangeLang = (value) => {
    // changeLanguage(value);
    changeLanguage(value);
    setIsShowLangList(false);
  };

  useEffect(() => {
    setSectionHeight(SectionRef.current.clientHeight);

    if (window.location.href.indexOf(".cn") !== -1) {
      changeLanguage("cn");
    } else {
      changeLanguage("tw");
    }
  }, []);

  return (
    <>
      <MainBGContent>
        <TopNavBar>
          <TopNavGrid>
            <Logo />
            <LogIn>
              {user?.roles.some((role) =>
                ACCESS_LEVELS[ADMINS].includes(role)
              ) ? (
                <ButtonLink as={Link} to="/admin" />
              ) : user?.roles.some((role) =>
                  ACCESS_LEVELS[ALL].includes(role)
                ) ? (
                <ButtonLink as={Link} to="/teacher" />
              ) : null}

              {user ? (
                <ButtonLink
                  as={Link}
                  to="/"
                  linkname="logout"
                  onClick={signout}
                />
              ) : (
                <ButtonLink
                  linkname="login"
                  onClick={() =>
                    signin(
                      `${MYBESTUSER}/authorize` +
                        qs.stringify(
                          {
                            client_id: CLIENTID,
                            response_type: "code",
                            redirect_uri:
                              window.location.origin + "/mybestuser",
                            state: "state",
                            scope: "api.mybestta.com api.mybestuser.com"
                          },
                          { addQueryPrefix: true }
                        )
                    )
                  }
                />
              )}
            </LogIn>

            <LangSelect>
              <DefaultLang onClick={ToggleLangList}>
                <div style={{ fontSize: "0.7em" }}>
                  {language === "cn"
                    ? "简体中文"
                    : language === "tw"
                    ? "繁體中文"
                    : "English"}
                </div>
                <div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </DefaultLang>
              {isShowLangList ? (
                <DropDown>
                  <LangUl>
                    <LangLi
                      value="cn"
                      onClick={(e) => {
                        handleChangeLang(e.target.value);
                      }}
                    >
                      简体中文
                    </LangLi>
                    <LangLi
                      value="tw"
                      onClick={(e) => {
                        handleChangeLang(e.target.value);
                      }}
                    >
                      繁體中文
                    </LangLi>
                    <LangLi
                      value="en"
                      onClick={(e) => {
                        handleChangeLang(e.target.value);
                      }}
                    >
                      English
                    </LangLi>
                  </LangUl>
                </DropDown>
              ) : null}
            </LangSelect>
          </TopNavGrid>
        </TopNavBar>
        <Content id="scroller">
          <Section style={{ width: "80%" }} id="section_one" ref={SectionRef}>
            <RatioBox ratio={1 / 1} style={{ width: "50%" }}>
              <Regular>
                <SVGArea>
                  <MyBestTASVG
                    draggable="false"
                    src={myBestTa}
                    alt="MyBestTA"
                  />
                  <ChildrenSVG
                    draggable="false"
                    src={children}
                    alt="Children"
                  />
                </SVGArea>
              </Regular>
            </RatioBox>
            <Section1DescriptionArea>
              <DescriptionTitle>
                <T>System X High Efficiency X Rapid</T>
              </DescriptionTitle>
              <div style={{ fontSize: "2.2em" }}>Teaching Assistant</div>
              <div style={{ fontSize: "1.6em" }}>
                {converter({
                  en: "完整教學資源整合平台",
                  tw: "完整教學資源整合平台",
                  cn: "完整教学资源整合平台"
                })}
              </div>
              <div style={{ fontSize: "1em", paddingTop: "1em" }}>
                {converter({
                  en: "系統化教學流程幫助老師快速備課，教學內容完美整合於TA老師中。 依據課程體系細分級別單元，含課本大圖，音頻與視頻等，更設計多元互動遊戲與教具點子，營造學生於課堂中積極練習與表達。每個教學環節含有教學筆記方便老師觀看教學重點；Tool Bar工具欄設計轉盤， 骰子， 計分板等小工具讓課堂更有趣。",
                  tw: "系統化教學流程幫助老師快速備課，教學內容完美整合於TA老師中。 依據課程體系細分級別單元，含課本大圖，音頻與視頻等，更設計多元互動遊戲與教具點子，營造學生於課堂中積極練習與表達。每個教學環節含有教學筆記方便老師觀看教學重點；Tool Bar工具欄設計轉盤， 骰子， 計分板等小工具讓課堂更有趣。",
                  cn: "系统化教学流程帮助老师快速备课，教学内容完美整合于TA老师中。 依据课程体系细分级别单元，含课本大图，音频与视频等，更设计多元互动游戏与教具点子，营造学生于课堂中积极练习与表达。每个教学环节含有教学笔记方便老师观看教学重点；Tool Bar工具栏设计转盘， 骰子， 计分板等小工具让课堂更有趣。"
                })}
              </div>
            </Section1DescriptionArea>
            <ScrollToAnchorButton
              onClick={() => {
                scrollToAnchor(SectionHeight);
              }}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </ScrollToAnchorButton>
          </Section>
          <Section style={{ width: "100%" }} id="section_two">
            <Part1>
              <CustomizationDiv>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "0.5em"
                  }}
                >
                  <img
                    style={{ width: "3.5em" }}
                    draggable="false"
                    src={downloadIcon}
                    alt="Download"
                  />
                  <div style={{ fontSize: "1.3em" }}>
                    <div>Download</div>
                    <div>
                      {converter({
                        en: "資源下載",
                        tw: "資源下載",
                        cn: "资源下载"
                      })}
                    </div>
                  </div>
                </div>
              </CustomizationDiv>

              <div style={{ marginLeft: "3.5em" }}>
                <Description>
                  {converter({
                    en: "單元與銜接考卷, 閃卡, 學習單等教學資源。",
                    tw: "單元與銜接考卷, 閃卡, 學習單等教學資源。",
                    cn: "单元与衔接考卷, 闪卡, 学习单等教学资源。"
                  })}
                </Description>
                <Description>
                  {converter({
                    en: "易拉寶, 海報, 學習能力指標等宣傳資源幫助學校快速招生。",
                    tw: "易拉寶, 海報, 學習能力指標等宣傳資源幫助學校快速招生。",
                    cn: "易拉宝, 海报, 学习能力指标等宣传资源帮助学校快速招生。"
                  })}
                </Description>
              </div>
              <img
                style={{ width: "18em" }}
                draggable="false"
                src={download}
                alt="Download"
              />
            </Part1>
            <Part2>
              <img
                style={{ width: "25em" }}
                draggable="false"
                src={video}
                alt="Video"
              />
              <CustomizationDiv>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "0.5em"
                  }}
                >
                  <img
                    style={{ width: "3.5em" }}
                    draggable="false"
                    src={videoIcon}
                    alt="Video"
                  />
                  <div style={{ fontSize: "1.3em" }}>
                    <div>Video</div>
                    <div>
                      {converter({
                        en: "培訓視頻",
                        tw: "培訓視頻",
                        cn: "培训视频"
                      })}
                    </div>
                  </div>
                </div>
              </CustomizationDiv>
              <Description>
                {converter({
                  en: "專題培訓視頻幫助老師隨時學習，提升教學質量。",
                  tw: "專題培訓視頻幫助老師隨時學習，提升教學質量。",
                  cn: "专题培训视频帮助老师随时学习，提升教学质量。"
                })}
              </Description>
            </Part2>
            <ScrollToAnchorButton
              onClick={() => {
                scrollToAnchor(SectionHeight * 2);
              }}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </ScrollToAnchorButton>
          </Section>
          <Section id="section_three">
            <SchoolContainer>
              <img
                style={{ width: "35em" }}
                draggable="false"
                src={schools}
                alt="Schools"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "0.5em"
                  }}
                >
                  <img
                    style={{ width: "3.5em" }}
                    draggable="false"
                    src={systemIcon}
                    alt="System"
                  />
                  <div style={{ fontSize: "1.3em" }}>
                    <div>Schools</div>
                    <div>
                      {converter({
                        en: "系統管理。",
                        tw: "系統管理。",
                        cn: "系统管理。"
                      })}
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "3.5em" }}>
                  <Description>
                    {converter({
                      en: "經營者能系統化管理老師使用狀況。",
                      tw: "經營者能系統化管理老師使用狀況。",
                      cn: "经营者能系统化管理老师使用状况。"
                    })}
                  </Description>
                </div>
              </div>
            </SchoolContainer>
            <ScrollToAnchorButton
              onClick={() => {
                scrollToAnchor(0);
              }}
            >
              <FontAwesomeIcon icon={faChevronUp} />
            </ScrollToAnchorButton>
          </Section>
          <Footer id="footer">
            <CompanyInfoArea>
              <DownloadArea>
                <InfoTitle>
                  <T>DownloadArea</T>
                </InfoTitle>

                <ChromeLink
                  title="ChromeSetup"
                  linkname="chrome"
                  href={`${MEDIA_STORAGE}/public/download/ChromeSetup${
                    os.includes("Mac") ? ".dmg" : ".exe"
                  }`}
                  download
                  lang={language}
                />

                <TeamviewerLink
                  title="TeamviewerSetup"
                  linkname="teamviewer"
                  href={`${MEDIA_STORAGE}/public/download/TeamViewer${
                    os.includes("Mac") ? ".dmg" : ".exe"
                  }`}
                  download
                  lang={language}
                />
                <AnyDeskLink
                  title="AnyDeskSetup"
                  linkname="anydesk"
                  href={`${MEDIA_STORAGE}/public/download/AnyDesk${
                    os.includes("Mac") ? ".dmg" : ".exe"
                  }`}
                  download
                  lang={language}
                />
              </DownloadArea>
            </CompanyInfoArea>
            <hr style={{ width: "80%", BorderColor: "#535353" }} />
            <Copyright style={{ height: "20%" }} />
          </Footer>
        </Content>
      </MainBGContent>
    </>
  );
};

export default LandingPage;
