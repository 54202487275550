import { roles } from "./userConfigs";

export const SUPER = "super";
export const ADMIN = "admin";
export const ADMINS = "admins";
export const ADMIN_ONLY = "admin_only";
export const EDITOR = "editor";
export const EDITOR_ONLY = "editor_only";
export const SALER = "saler";
export const SALER_ONLY = "saler_only";
export const PRINCIPAL = "principal";
export const PRINCIPAL_ONLY = "principal_only";
export const TEACHER = "teacher";
export const TEACHER_ONLY = "teacher_only";
export const STUDENT = "student";
export const USERS = "users";
export const ALL = "all";

export default {
  [SUPER]: [roles.SUPER],
  [ADMIN]: [roles.ADMIN, roles.SUPER],
  [ADMIN_ONLY]: [roles.ADMIN],
  [ADMINS]: [
    roles.SUPER,
    roles.ADMIN,
    roles.MELODY,
    roles.DEVELOPER,
    roles.EDUCATIONAL_CONS,
    roles.CUSTOMER_SERVICE_REP,
    roles.EDITOR
    // roles.SALES_REP,
  ],
  [EDITOR]: [roles.EDITOR, roles.ADMIN, roles.SUPER],
  [EDITOR_ONLY]: [roles.EDITOR],
  [SALER]: [roles.SALES_REP, roles.EDITOR, roles.ADMIN, roles.SUPER],
  [SALER_ONLY]: [roles.SALES_REP],
  [PRINCIPAL]: [roles.PRINCIPAL, roles.EDITOR, roles.ADMIN, roles.SUPER],
  [PRINCIPAL_ONLY]: [roles.PRINCIPAL],
  [TEACHER]: [
    roles.TEACHER,
    roles.PRINCIPAL,
    roles.EDITOR,
    roles.ADMIN,
    roles.SUPER
  ],
  [TEACHER_ONLY]: [roles.TEACHER],
  [STUDENT]: [roles.STUDENT],
  [USERS]: [roles.TEACHER, roles.PRINCIPAL],
  [ALL]: [
    roles.SUPER,
    roles.ADMIN,
    roles.MELODY,
    roles.DEVELOPER,
    roles.EDITOR,
    roles.EDUCATIONAL_CONS,
    roles.CUSTOMER_SERVICE_REP,
    // roles.SALES_REP,
    roles.TEACHER,
    roles.PRINCIPAL,
    roles.STUDENT,
    roles.SECRETARY
  ]
};
