import axios from "axios";
import { batch } from "react-redux";

import {
  FETCH_BOOKSETS,
  UPDATE_BOOKSET,
  LOADING_BOOKSETS,
  UPDATE_BOOK,
  FETCH_BOOKS
} from "./type";

import mediaServerAddress from "../config/mediaServerAddress";

/**
 * BOOKSET
 */

// fetch all the booksets
export const fetchBooksets =
  (options, callback) => async (dispatch, getStore) => {
    dispatch({ type: LOADING_BOOKSETS, payload: {} });

    let query = ``;

    if (options?.isHidden !== undefined)
      query = `${query}&isHidden=${options.isHidden}`;

    if (options?.route !== undefined) query = `${query}&route=${options.route}`;

    const route = `bookset${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { booksetMap }
    } = await axios.get(route);

    dispatch({ type: FETCH_BOOKSETS, payload: { booksetMap } });

    if (callback) callback();
  };

// save add book set
export const saveAddBookset =
  (
    formProps,
    {
      imageSrc,
      logoSrc,
      logoAltSrc,
      title,
      isHidden,
      productIds,
      logoIsHidden
    },
    callback
  ) =>
  async (dispatch, getStore) => {
    let imageId = null;
    let logoId = null;
    let logoAltId = null;

    if (imageSrc) {
      if (formProps[`image_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`image_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        imageId = image._id;
      }
    }

    if (logoSrc) {
      if (formProps[`logo_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`logo_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        logoId = image._id;
      }
    }

    if (logoAltSrc) {
      if (formProps[`logo_1`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`logo_1`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        logoAltId = image._id;
      }
    }

    const {
      data: { bookset }
    } = await axios.post(`bookset`, {
      title,
      isHidden,
      imageId,
      logoId,
      logoAltId,
      productIds,
      logoIsHidden
    });

    dispatch({ type: UPDATE_BOOKSET, payload: { bookset } });

    if (callback) callback();
  };

// save edit book set
export const saveEditBookset =
  (
    formProps,
    {
      imageSrc,
      logoSrc,
      logoAltSrc,
      title,
      isHidden,
      logoIsHidden,
      booksetId,
      productIds
    },
    { coverImage, logoImage, logoAltImage },
    callback
  ) =>
  async (dispatch, getStore) => {
    let imageId = null;
    let logoId = null;
    let logoAltId = null;

    const uselessImages = {};

    if (coverImage) {
      uselessImages[coverImage._id] = true;
    }

    if (logoImage) {
      uselessImages[logoImage._id] = true;
    }

    if (logoAltImage) {
      uselessImages[logoAltImage._id] = true;
    }

    if (imageSrc) {
      if (formProps[`image_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`image_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        imageId = image._id;
      } else {
        imageId = coverImage._id;
        delete uselessImages[coverImage._id];
      }
    }

    if (logoSrc) {
      if (formProps[`logo_0`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`logo_0`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        logoId = image._id;
      } else {
        logoId = logoImage._id;
        delete uselessImages[logoImage._id];
      }
    }

    if (logoAltSrc) {
      if (formProps[`logo_1`]) {
        const fd = new FormData();
        fd.append(`image`, formProps[`logo_1`]);

        //upload new image
        const {
          data: { image }
        } = await mediaServerAddress.post(`image`, fd);
        logoAltId = image._id;
      } else {
        logoAltId = logoAltImage._id;
        delete uselessImages[logoAltImage._id];
      }
    }

    const {
      data: { bookset }
    } = await axios.patch(`bookset/${booksetId}`, {
      patch: {
        action: `changeCover`,
        data: {
          title,
          isHidden,
          logoIsHidden,
          imageId,
          logoId,
          logoAltId,
          productIds
        }
      }
    });

    //clean up old images
    await axios.delete(`image`, {
      data: { imageIds: Object.keys(uselessImages) }
    });

    dispatch({ type: UPDATE_BOOKSET, payload: { bookset } });

    if (callback) callback();
  };

export const moveBookset =
  (from, to, callback) => async (dispatch, getStore) => {
    const {
      data: { booksetMap }
    } = await axios.patch(`moveBookset`, {
      patch: {
        data: {
          from,
          to
        }
      }
    });

    dispatch({ type: FETCH_BOOKSETS, payload: { booksetMap } });

    if (callback) callback();
  };

// delete bookset
export const deleteBookset =
  (booksetId, callback) => async (dispatch, getStore) => {
    // delete bookset
    await axios.delete(`bookset/${booksetId}`);

    //update booksets
    const {
      data: { booksetMap }
    } = await axios.get(`bookset`);

    dispatch({ type: FETCH_BOOKSETS, payload: { booksetMap } });

    if (callback) callback();
  };

/**
 * Book type
 */

//save book type
export const saveBooktype =
  (mode, booktypeName, _booksetId, _booktypeId, callback) =>
  async (dispatch, getStore) => {
    const action = mode === "add" ? `addBooktype` : `updateBooktype`;
    const {
      data: { bookset }
    } = await axios.patch(`bookset/${_booksetId}`, {
      patch: {
        action: action,
        data: {
          title: booktypeName,
          booktypeId: _booktypeId
        }
      }
    });

    dispatch({ type: UPDATE_BOOKSET, payload: { bookset } });

    _booktypeId = bookset.bookTypes[bookset.bookTypes.length - 1]._id;

    if (mode === "add") {
      //add one book automatically after adding a component
      const {
        data: { book }
      } = await axios.post(`book`, {
        title: `Level 1`,
        booksetId: _booksetId,
        booktypeId: _booktypeId,
        imageId: null,
        level: 0
      });
      dispatch({ type: UPDATE_BOOK, payload: { book } });

      //update bookset.bookTypes._book
      const _bookId = book._id;
      const {
        data: { bookset }
      } = await axios.patch(`bookset/${_booksetId}`, {
        patch: {
          action: "addBook",
          data: {
            booktypeId: _booktypeId,
            bookId: _bookId
          }
        }
      });

      dispatch({ type: UPDATE_BOOKSET, payload: { bookset } });
    }

    if (callback) callback();
  };

// move bookType
export const moveBookType =
  ({ booksetId, booktypeId }, to, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { bookset }
    } = await axios.patch(`bookset/${booksetId}`, {
      patch: {
        action: "MOVE_BOOKTYPE",
        data: { booktypeId, to }
      }
    });

    dispatch({ type: UPDATE_BOOKSET, payload: { bookset } });

    if (callback) callback();
  };

// duplicate book
export const duplicateBooks =
  ({ bookIds, bookTypeId, booksetId }, callback, mode) =>
  async (dispatch, getStore) => {
    const {
      data: { bookset }
    } = await axios.patch(`bookset/${booksetId}`, {
      patch: {
        action: "duplicate_books",
        data: { bookIds, bookTypeId, mode: mode || "" }
      }
    });

    const {
      data: { bookMap }
    } = await axios.get(`book`);

    batch(() => {
      dispatch({ type: UPDATE_BOOKSET, payload: { bookset } });
      dispatch({ type: FETCH_BOOKS, payload: { bookMap } });
    });

    if (callback) callback();
  };

// duplicate booksets
export const duplicateBooksets =
  ({ booksetIds }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { booksetMap }
    } = await axios.patch(`bookset`, {
      patch: {
        action: "duplicate_booksets",
        data: { booksetIds }
      }
    });

    dispatch({ type: FETCH_BOOKSETS, payload: { booksetMap } });

    if (callback) callback();
  };

// delete bookType
export const deleteBookType =
  ({ booksetId, bookTypeIndex }, callback) =>
  async (dispatch, getStore) => {
    // delete bookType & update bookset.bookTypes
    const {
      data: { bookset }
    } = await axios.patch(`bookset/${booksetId}`, {
      patch: {
        action: "deleteBookType",
        data: {
          bookTypeIndex
        }
      }
    });

    dispatch({ type: UPDATE_BOOKSET, payload: { bookset } });

    if (callback) callback();
  };

//handle bookset in favorite list
export const handleFavorite =
  (booksetId, callback) => async (dispatch, getStore) => {
    await axios.patch(`account/bookset/${booksetId}`);
    if (callback) callback();
  };
