// auth
export const AUTH_USER = "auth_user";
export const AUTH_ERROR = "auth_error";
export const CLEAN_AUTH = "clean_auth";

// modal
export const OPEN_MODAL = "open_modal";
export const CLOSE_MODAL = "close_modal";

// animation modal
export const OPEN_ANIMATION_MODAL = "open_animation_modal";
export const CLOSE_ANIMATION_MODAL = "close_animation_modal";

// training
export const FETCH_TRAININGS = "fetch_trainings";
export const UPDATE_TRAINING = "update_training";
export const UPDATE_TRAININGS = "update_trainings";
export const LOADING_TRAININGS = "loading_trainings";

// bookset
export const FETCH_BOOKSETS = "fetch_booksets";
export const UPDATE_BOOKSET = "update_bookset";
export const UPDATE_BOOKSETS = "update_booksets";
export const LOADING_BOOKSETS = "loading_booksets";

//downloads
export const FETCH_CATEGORYS = "fetch_categorys";
export const UPDATE_CATEGORY = "update_category";
export const UPDATE_CATEGORYS = "update_categorys";
export const LOADING_CATEGORYS = "loading_categorys";

export const FETCH_FOLDERS = "fetch_folders";
export const UPDATE_FOLDER = "update_folder";
export const LOADING_FOLDERS = "loading_folders";

//videos
export const FETCH_VIDEO_CATEGORYS = "fetch_video_categorys";
export const UPDATE_VIDEO_CATEGORY = "update_video_category";
export const UPDATE_VIDEO_CATEGORYS = "update_video_categorys";
export const LOADING_VIDEO_CATEGORYS = "loading_video_categorys";

export const FETCH_VIDEO_FOLDERS = "fetch_video_folders";
export const UPDATE_VIDEO_FOLDER = "update_video_folder";
export const LOADING_VIDEO_FOLDERS = "loading_video_folders";

export const FETCH_VIDEOLISTS = "fetch_videolists";
export const UPDATE_VIDEOLIST = "update_videolist";
export const LOADING_VIDEOLISTS = "loading_videolists";

// resource
export const FETCH_RESOURCES = "fetch_resources";
export const UPDATE_RESOURCE = "update_resource";
export const UPDATE_RESOURCES = "update_resources";
export const LOADING_RESOURCES = "loading_resources";

// book
export const FETCH_BOOKS = "fetch_books";
export const UPDATE_BOOK = "update_book";
export const UPDATE_BOOKS = "update_books";
export const LOADING_BOOKS = "loading_books";

// unit
export const FETCH_UNITS = "fetch_units";
export const UPDATE_UNIT = "update_unit";
export const UPDATE_UNITS = "update_units";
export const LOADING_UNITS = "loading_units";

// section
export const FETCH_SECTIONS = "fetch_sections";
export const UPDATE_SECTION = "update_section";
export const UPDATE_SECTIONS = "update_sections";
export const LOADING_SECTIONS = "loading_sections";

// page
export const FETCH_PAGES = "fetch_pages";
export const UPDATE_PAGE = "update_page";
export const UPDATE_PAGES = "update_pages";
export const LOADING_PAGES = "loading_pages";
export const FETCH_PAGES_NUMBER = "fecth_pages_number";

export const REMOVE_PAGE = "remove_page";
export const RESTORE_PAGE = "restore_page";
export const RESTORE_PAGES = "restore_pages";

// editor
export const OPEN_EDITOR = "open_editor";
export const CLOSE_EDITOR = "close_editor";

export const CHANGE_TITLE = "change_title";
export const CHANGE_TEACHING_NOTES = "change_teaching_notes";
export const CHANGE_REMARK_TEACHING_NOTES = "change_remark_teaching_notes";
export const CHANGE_TEXT = "change_text";
export const CHANGE_REMARK_TEXT = "change_remark_text";

export const ADD_IMAGE = "add_image";
export const CHANGE_IMAGE = "change_image";
export const REMOVE_IMAGE = "remove_image";

export const ADD_VIDEO = "add_video";
export const CHANGE_VIDEO = "change_video";
export const REMOVE_VIDEO = "remove_video";
export const CHANGE_VIDEO_STATUS = "change_video_status";

export const ADD_AUDIO = "add_audio";
export const CHANGE_AUDIO = "change_audio";
export const MOVE_AUDIO = "move_audio";
export const REMOVE_AUDIO = "remove_audio";
export const DELETE_AUDIO = "delete_audio";

export const ADD_CARD = "add_card";
export const CHANGE_CARD_TEXT = "change_card_text";
export const CHANGE_CARD_REMARK_TEXT = "change_card_remark_text";
export const CHANGE_CARD_AUDIO = "change_card_audio";
export const CHANGE_CARD_IMAGE = "change_card_image";
export const REMOVE_CARD_IMAGE = "remove_card_image";
export const REMOVE_CARD_AUDIO = "remove_card_audio";
export const DELETE_CARD = "delete_card";
export const MOVE_CARD = "move_card";

export const ADD_FILE = "add_file";

export const ADD_PLAYGROUND = "add_playground";
export const DELETE_PLAYGROUND = "delete_playground";
export const ADD_PLAYGROUNDLEVEL = "add_playgroundlevel";

// toolbar
export const OPEN_TOOLBAR = "open_toolbar";
export const CLOSE_TOOLBAR = "close_toolbar";
export const ADD_TOOLBAR_ICON = "add_toolbarIcon";
export const MOVE_TOOLBAR_ICON = "move_toolbarIcon";
export const CHANGE_TOOLBAR_ICON = "change_toolbarIcon";
export const STORE_TOOLBAR_GRID = "store_toolbarGrid";
export const RESIZE_TOOLBAR_GRID = "resize_toolbarGrid";
export const DELETE_TOOLBAR_ICON = "delete_toolbarIcon";
export const UNDO_TOOLBAR_HISTORY = "undo_toolbarHistory";
export const REDO_TOOLBAR_HISTORY = "redo_toolbarHistory";
export const INITIAL_TOOLBAR_HISTORY = "init_toolbarHistory";
export const DELETEALL_TOOLBAR_HISTORY = "deleteAll_toolbarHistory";
export const DRAWLINE_TOOLBAR_HISTORY = "drawLine_toolbarHistory";
export const ADD_TOOLBAR_TEXT = "add_toolbarText";
export const STORE_TOOLBAR_TEXT = "store_toolbarText";
export const MOVE_TOOLBAR_TEXT = "move_toolbarText";
export const DELETE_TOOLBAR_TEXT = "delete_toolbarText";
export const RESIZE_TOOLBAR_TEXT = "resize_toolbarText";
export const ADD_TOOLBAR_SHAPE = "add_toolbar_shape";
export const ADD_TOOLBAR_SCREENSHOT = "add_toolbar_screenshot";
export const DELETE_TOOLBAR_SCREENSHOT = "delete_toolbar_screenshot";
export const STORE_TOOLBAR_SCOREBOARD_INFO = "store_toolbar_scoreboard_info";

// audio player
export const LOAD_AUDIO = "load_audio";
export const PLAY_AUDIO = "play_audio";
export const PAUSE_AUDIO = "pause_audio";
export const STOP_AUDIO = "stop_audio";
export const SET_AUDIO_DURATION = "set_audio_duration";
export const SET_AUDIO_CURRENT_TIME = "set_audio_current_time";
export const CONTROL_AUDIO_CURRENT_TIME = "control_audio_current_time";

// error
export const SET_ERROR = "set_error";
export const CLOSE_ERROR = "close_error";

// warning
export const SET_WARNING = "set_warning";
export const CLOSE_WARNING = "close_warning";

//notify
export const SET_NOTIFY = "set_notify";
export const CLOSE_NOTIFY = "close_notify";

// load
export const SET_LOAD = "set_load";
export const CLOSE_LOAD = "close_load";

// contextmenu
export const SET_CONTEXTMENU = "set_contextmenu";
export const CLOSE_CONTEXTMENU = "close_contextmenu";

// school
export const FETCH_SCHOOLS = "fetch_schools";
export const UPDATE_SCHOOL = "update_school";
export const UPDATE_SCHOOLS = "updeta_schools";

// notice
export const ADD_NOTICE = "add_notice";
export const DELETE_NOTICE = "delete_notice";

// save words
export const SAVE_WORDS_ORDER = "save_words_order";

// full screen
export const OPEN_FULLSCREEN = "open_fullScreen";
export const CLOSE_FULLSCREEN = "close_fullScreen";

// logs
export const FETCH_LOGS = "fetch_logs";
export const LOADING_LOGS = "loading_logs";
export const VIEW_LOG = "view_log";

// announcement
export const FETCH_ANNOUNCEMENTS = "fetch_announcements";
export const UPDATE_ANNOUNCEMENT = "update_announcemen";
export const UPDATE_ANNOUNCEMENTS = "updeta_announcements";
export const LOADING_ANNOUNCEMENTS = "loading_announcements";
export const DELETE_ANNOUNCEMENT = "delete_announcement";

// account
export const FETCH_ACCOUNTS = "fetch_accounts";
export const UPDATE_ACCOUNT = "update_account";
export const UPDATE_ACCOUNTS = "update_accounts";

//teacher mode
export const CHANGE_TEACHINGMODE = "change_teachingmode";

//teacher viewed time
export const FETCH_CLASSALLDETAILS = "fetch_classalldetails";
export const FETCH_BOOKVIEWEDDETAILS = "fetch_bookvieweddetails";
export const FETCH_SECTIONDETAILS = "fetch_sectiondetails";

//teacher comment
export const FETCH_COMMENTS = "fetch_comments";
export const UPDATE_COMMENT = "update_comment";

// style view
export const FETCH_STYLEVIEWS = "fetch_styleviews";
export const UPDATE_STYLEVIEW = "update_styleview";
