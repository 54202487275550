import { combineReducers } from "redux";

// reducers
import { reducer as formReducer } from "redux-form";
import auth from "./authReducer";
import bookset from "./booksetReducer";
import resource from "./resourceReducer";
import book from "./bookReducer";
import unit from "./unitReducer";
import section from "./sectionReducer";
import page from "./pageReducer";
import modal from "./modalReducer";
import toolbar from "./toolbarReducer";
import audioPlayer from "./audioPlayerReducer";
import error from "./errorReducer";
import warning from "./warningReducer";
import notify from "./notifyReducer";
import contextmenu from "./contextmenuReducer";
import load from "./loadReducer";
import school from "./schoolReducer";
import notice from "./noticeReducer";
import account from "./accountReducer";

import fullScreen from "./fullScreenReducer";
import log from "./logReducer";
import training from "./trainingReducer";
import animation from "./animationModalReducer";
import announcement from "./announcementReducer";
import download from "./downloadsReducer";
import video from "./videosReducer";

import teachingMode from "./teachingModeReducer";
import teacherViewedStatus from "./teacherViewedStatusReducer";

import comment from "./commentReducer";
import styleview from "./styleviewReducer";

export default combineReducers({
  form: formReducer,
  auth,
  bookset,
  resource,
  book,
  unit,
  section,
  page,
  modal,
  toolbar,
  audioPlayer,
  error,
  warning,
  notify,
  contextmenu,
  load,
  school,
  notice,
  account,
  fullScreen,
  log,
  training,
  animation,
  announcement,
  download,
  video,
  teachingMode,
  teacherViewedStatus,
  comment,
  styleview
});
